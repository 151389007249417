import React, {FC} from "react";
import {Skeleton} from "shared/components";

interface IIconGroup {
    iconSize?: string;
    icons?: string[];
    pending?: boolean;
}

export const IconGroup: FC<IIconGroup> = ({
                                              iconSize = "6",
                                              icons= [],
                                              pending = false
                                          }) => {
    if (pending) {
        return (
                <div className={`flex rounded-full`}>
                    <Skeleton className={`text-${iconSize}`}>
                        <div className={`size-${iconSize} rounded-full`}></div>
                    </Skeleton>
                </div>
        )
    }

    return (
        <div className="flex">
            {
                icons.map((icon, index) => {
                    return (
                        <div key={icon} className={`flex flex-row-reverse ${(index > 0) ? "-ml-3" : ""}`}>
                            <div className={`text-${iconSize}`}>{icon}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}