import {FC, ReactNode} from "react";
import {telegramPaddings} from "shared/lib/telegram-paddings";

interface IHeader {
    children: ReactNode;
    backgroundColor?: string;
    isFullWidth?: boolean;
    className?: string;
}

export const PageHeader: FC<IHeader> = ({
                                            children,
                                            backgroundColor = "white",
                                            isFullWidth = false,
                                            className,
                                        }) => {
    const {paddingTop} = telegramPaddings()

    const full = isFullWidth ? "-mx-2" : ""

    return (
        <div className={`p-4 rounded-b-3xl overflow-hidden relative z-10 ${className} ${full} bg-${backgroundColor}`}
             style={{marginTop: `-${paddingTop}`, paddingTop: paddingTop}}>
            {children}
        </div>
    )
}