import {useGate, useUnit} from "effector-react";
import {UserGames} from "widgets/user-games";
import {UserAvatar} from "features/user-avatar";
import {
    Button, PageHeader,
    PageWrapper,
    ScrollArea,
    ScrollAreaContentContainer,
    TypographyH3
} from "shared/components";
import {userProfilePageGate} from "./model";
import {$sessionUser} from "entities/session";

export const UserProfilePage = () => {

    useGate(userProfilePageGate)

    const [userData] = useUnit([$sessionUser])

    console.log(userData)

    return (
        <PageWrapper>
            <div className="flex flex-col gap-2">
                <PageHeader>
                    <div className="flex flex-col gap-4">
                        <UserAvatar type="full" avatarSize="size-16" userName={userData?.userName} photoUrl={userData?.photoUrl} theme="dark"/>
                        <div>
                            <Button>Редактировать данные</Button>
                        </div>
                    </div>
                </PageHeader>
                <ScrollArea>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Радар</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Команда</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Виды спорта</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Городские площадки</ScrollAreaContentContainer>
                </ScrollArea>
                <div className="flex flex-col gap-5">
                    <TypographyH3>Я играю</TypographyH3>
                    <UserGames/>
                    <div><Button variant="outline" >Добавить</Button></div>
                </div>

            </div>
        </PageWrapper>
    )
}