import {useGate, useUnit} from "effector-react";
import {eventPageGate} from "pages/event-page/model/common";
import {UserAvatar} from "features/user-avatar";
import {$event} from "entities/event";
import {
    Button,
    EventContentContainer,
    PageHeader,
    PageWrapper, ScrollArea,
    TypographyBody, TypographyCaption,
    TypographyH2,
    TypographyH5, TypographySwitch
} from "shared/components";
import {icons, sportTypes} from "shared/constants";
import {BasicInformation} from "./ui/basic-information";

export const EventPage = () => {
    useGate(eventPageGate)

    const event = useUnit($event)

    console.log(event)

    return(
        <PageWrapper>
            <div className="flex flex-col gap-2">
                <PageHeader>
                    <div className="text-[32px] min-w-16 ">{icons[event?.sport.code as keyof typeof sportTypes]}</div>
                    <TypographyH2 className="text-black text-3xl w-1/2 z-30 mb-6">{event?.sport.name}</TypographyH2>
                    <BasicInformation dateTime={event?.dateTime} duration={event?.duration} cost={event?.cost} ageFrom={event?.ageFrom} ageTo={event?.ageTo}/>
                </PageHeader>
                <EventContentContainer title="Место">
                    <div className="flex flex-col gap-2">
                        <TypographyH5 className="text-black text-sm opacity-50">Название площадки</TypographyH5>
                        <TypographyBody className="text-black text-base">{event?.location.name}</TypographyBody>
                    </div>
                    <div className="flex flex-col gap-2">
                        <TypographyH5 className="text-black text-sm opacity-50">Адрес</TypographyH5>
                        <TypographyBody className="text-black text-base">{event?.location.address}</TypographyBody>
                    </div>
                    <div className="flex flex-col gap-2">
                        <TypographyH5 className="text-black text-sm opacity-50">Описание</TypographyH5>
                        <TypographyBody className="text-black text-base">{event?.location.description}</TypographyBody>
                    </div>
                    <div className="flex flex-col gap-2">
                        <TypographyH5 className="text-black text-sm opacity-50">Фотографии</TypographyH5>
                        <ScrollArea>
                            {
                                event?.location.photos.map(photo => (<img src={photo.photoUrl}/>))
                            }
                        </ScrollArea>
                    </div>
                    <div className="flex flex-col gap-2">
                        <TypographyH5 className="text-black text-sm opacity-50">Дополнительные сведения</TypographyH5>
                        <ul className="list-disc pl-5">
                            {
                                event?.location.properties.map(prop => {
                                    return (
                                        <li>{prop.groupName
                                                ? <TypographySwitch className="text-black text-base">{`${prop.groupName} (${prop.name})`}</TypographySwitch>
                                                : <TypographySwitch className="text-black text-base">{prop.name}</TypographySwitch>
                                        }</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="flex gap-2">
                        <Button className="rounded-full" variant="secondary">На карте</Button>
                        <Button className="rounded-full" variant="secondary">Поделиться</Button>
                    </div>
                </EventContentContainer>
                <EventContentContainer title="Организатор">
                    <UserAvatar userName={event?.organizer.userName} photoUrl={event?.organizer.photoUrl} theme="dark"/>
                    <Button className="rounded-full" variant="secondary">Написать</Button>
                </EventContentContainer>
                <EventContentContainer>
                    <div className="flex justify-between">
                        <TypographyH5 className="text-black text-xl">Игроки</TypographyH5>
                        {/*//@ts-expect-error*/}
                        <TypographyH5 className="flex text-black text-xl gap-1">{`${event?.participants.length}`}<p className="opacity-50">из</p>{`${event?.seats}`}</TypographyH5>
                    </div>
                    <div className="flex flex-col gap-2">
                        {
                            //@ts-expect-error
                            Boolean(event?.participants.length)
                            //@ts-expect-error
                                    ? event?.participants.map((participant) => {
                                        return <UserAvatar userName={participant.userName} photoUrl={participant.photoUrl} theme="dark"/>
                                    })
                                    : <TypographyBody className="text-black text-base">Игроков пока нет</TypographyBody>
                        }
                    </div>
                    <Button className="flex justify-between rounded-full"><div>Участвовать</div><TypographyCaption className="text-white text-sm">{event?.cost.toString()+" ₽"}</TypographyCaption></Button>
                </EventContentContainer>
            </div>
        </PageWrapper>
    )
}