export function telegramPaddings(): { paddingTop: string | number; paddingBottom: string } {
    //TODO 1) в типах нет поля safeAreaInset 2) Возможно есть способ лучше для определения в каком режиме открылось приложение
    //@ts-expect-error
    const isTopSafeAreaInset = window.Telegram?.WebApp?.contentSafeAreaInset?.top;
    //@ts-expect-error
    const isBottomSafeAreaInset = window.Telegram?.WebApp?.contentSafeAreaInset?.bottom;

    const paddingTop = isTopSafeAreaInset ? `calc(2rem + ${isTopSafeAreaInset}px)` : "1rem";
    const paddingBottom = isBottomSafeAreaInset ? `calc(1rem + ${isBottomSafeAreaInset}px)` : "1rem";

    return {
        paddingTop,
        paddingBottom,
    };
}