import {FC} from "react";
import {
    EventContentContainer,
    AvatarGroup,
    TypographyCaption, TypographyH3
} from "shared/components"
import {sportTypes, icons} from "shared/constants";
import {dateTimeTransform} from "shared/lib/date-time-transform";

interface IEventCard {
    sportCode: keyof typeof sportTypes;
    address?: string;
    dateTime: string;
    cost: number;
    seats: number;
    participants: TParticipants[];
    onClick: (arg1: { id: string }) => {};
    eventId: number;
}

type TParticipants = {
    id: number;
    userName: string;
    photoUrl: string
}

export const EventCard: FC<IEventCard> = ({
                                              sportCode,
                                              address,
                                              dateTime,
                                              cost,
                                              seats,
                                              participants,
                                              onClick,
                                              eventId
                                          }) => {

    const photos: string[] = participants.map((el) => el.photoUrl)
    const {time} = dateTimeTransform(dateTime)

    return (
        <EventContentContainer>
            <div onClick={() => onClick({id: eventId.toString()})} className="flex flex-col gap-2 -m-2">
                <div className="flex items-center gap-4">
                    <div className="text-[32px] min-w-16">{icons[sportTypes[sportCode]]}</div>
                    <div>
                        <TypographyH3 className="text-black text-base">Школа №88</TypographyH3>
                        <TypographyCaption className="text-slate-400 text-xs">г. Уфа, Ленина, 14</TypographyCaption>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <TypographyCaption className="text-black min-w-16 text-xl">{time}</TypographyCaption>
                    <div className="flex items-center gap-3">
                        <AvatarGroup photos={photos}/>
                        <TypographyCaption className="text-black text-sm">{`${participants.length} из ${seats}`}</TypographyCaption>
                    </div>
                    <TypographyCaption className="text-black text-base ml-auto">{`${cost} ₽`}</TypographyCaption>
                </div>
            </div>
        </EventContentContainer>
    )
}