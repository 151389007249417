import React, {FC} from "react";
import {
    Checkbox,
    Drawer, DrawerClose,
    DrawerContent,
    DrawerTrigger, IconGroup,
    Skeleton, TypographyCaption,
    TypographyH5
} from "shared/components";
import {CloseButtonIcon} from "shared/components/img-components/close-button-icon";

interface IMultiSelectDrawer {
    MultiSelectOptions?: TMultiSelectOption[] | null
    onSelect?: (arg1: string[]) => void;
    title?: string;
    values?: TMultiSelectOption['value'][];
    pending?: boolean;
}

export type TMultiSelectOption = {
    value: string,
    icon: string,
    name: string,
}

export const MultiSelectDrawer: FC<IMultiSelectDrawer> = ({
                                                              MultiSelectOptions,
                                                              onSelect = () => {
                                                              },
                                                              title = "Фильтр:",
                                                              values = [],
                                                              pending = false,
                                                          }) => {
    if (pending) {
        return (
            <Skeleton className="inline-flex items-center w-28 h-10 px-3 py-[8px] rounded-full border border-white/50"/>
        )
    }

    function handleSelect(item: string) {
        if (values.includes(item)) {
            onSelect(values.filter((value) => value !== item))
        } else {
            onSelect([...values, item]);
        }
    }

    return (
        <Drawer>
            <DrawerTrigger asChild>
                <div className="inline-flex w-min items-center h-10 px-3 py-[8px] rounded-full border border-white/50">
                    <TypographyCaption className="text-white text-sm">{title}</TypographyCaption>
                    <div className="inline-flex">
                        <IconGroup
                            icons={MultiSelectOptions?.filter((opt) => values.includes(opt.value)).map((opt) => opt.icon)}/>
                    </div>
                </div>
            </DrawerTrigger>
            <DrawerContent className="px-4 py-6 gap-6">
                <div className="flex justify-between">
                    <TypographyH5 className="text-black text-xl">{title}</TypographyH5>
                    <DrawerClose asChild>
                        <button className="flex"><CloseButtonIcon /></button>
                    </DrawerClose>
                </div>
                <ul className="flex flex-col gap-4">
                    {MultiSelectOptions?.map((option) => (
                        <li key={option.value} className="flex justify-between">
                            <div
                                className="flex gap-2 items-center"
                            >
                                {option.icon}<TypographyCaption className="text-black text-sm">{option.name}</TypographyCaption>
                            </div>
                            <Checkbox checked={values.includes(option.value)}
                                      onCheckedChange={() => handleSelect(option.value)}/>
                        </li>
                    ))}
                </ul>
            </DrawerContent>
        </Drawer>
    )
};


