import {sample} from "effector";
import {createGate} from "effector-react";
import {attachGetEventFx} from "entities/event";
import {eventPageRoute} from "shared/routes";

export const eventPageGate = createGate()

sample({
    source: eventPageRoute.$params,
    clock: eventPageGate.open,
    fn: ({id}) => id,
    target: attachGetEventFx
})