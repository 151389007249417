import React, {FC} from "react";
import {UploadIcon} from "shared/components";

interface IPhotoInput {
    onChange?: (file: File | null) => void;
}

export const PhotoInput: FC<IPhotoInput> = ({ onChange }) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const file = e.target.files?.[0] || null;
            onChange(file);
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className="relative">
            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
            />
            <div
                className="w-[96px] h-[72px] flex-shrink-0 rounded-lg border border-gray-300 bg-gray-200 flex items-center justify-center cursor-pointer"
                onClick={handleClick}
            >
                <UploadIcon/>
            </div>
        </div>
    );
};