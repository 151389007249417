import {FC, MouseEventHandler, ReactElement} from "react";
import {
    Carousel,
    CarouselContent,
    CarouselItem, CarouselNext,
    CatDark, TypographyBodySmall,
    TypographyH1
} from "shared/components";

interface IOnboarding {
    isOpen: boolean
    backgroundColor?: string;
    imgComponent?: ReactElement;
    imgClassName?: string;
    onboardingContent: { title: string, text: string }[];
    lastSlideOnClick: MouseEventHandler<HTMLButtonElement>;
}

export const Onboarding: FC<IOnboarding> = ({
                                                onboardingContent,
                                                backgroundColor = "bg-orange-400",
                                                imgClassName = "absolute inset-0 w-full h-full object-cover z-10",
                                                imgComponent = <CatDark className={imgClassName}/>,
                                                isOpen,
                                                lastSlideOnClick
                                            }) => {

    return (
        <div className={`h-screen w-screen ${backgroundColor}`}>
            <div className="absolute inset-0 flex flex-col-reverse z-20">
                <div className="bg-slate-800 flex flex-col px-4 pt-6 pb-12 rounded-t-3xl gap-10 container">
                    <Carousel className="w-full flex flex-col gap-10">
                        <CarouselContent>
                            {onboardingContent.map(({title, text}, index) => (
                                <CarouselItem key={index} className="flex flex-col gap-10">
                                    <TypographyBodySmall>{`${index + 1} / ${onboardingContent.length}`}</TypographyBodySmall>
                                    <div className="flex flex-col gap-6">
                                        <TypographyH1 className="text-white ">{title}</TypographyH1>
                                        <TypographyBodySmall>{text}</TypographyBodySmall>
                                    </div>
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                        <CarouselNext size="sm" onClick={lastSlideOnClick}>Далее</CarouselNext>
                    </Carousel>
                </div>
            </div>
            {imgComponent}
        </div>
    )
}

