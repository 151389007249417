import React, {FC} from "react";
import {Avatar, AvatarImage} from "shared/components";
import defaultAvatar from "shared/assets/default-avatar.svg";

interface IPhotoCloud {
    className?: string;
    photos?: string[];
}

export const PhotoCloud: FC<IPhotoCloud> = ({
                                                className,
                                                photos= [null]
                                            }) => {

    const photoParam = [
        {size: "size-12", right: "right-[-159px]", bottom: "bottom-[-38px]", src: photos[0]},
        {size: "size-12", right: "right-[-93px]", bottom: "bottom-[-89px]", src: photos[1]},
        {size: "size-12", right: "right-[-220px]", bottom: "bottom-[-70px]", src: photos[2]},
        {size: "size-12", right: "right-[-35px]", bottom: "bottom-[-128px]", src: photos[3]},
        {size: "size-6", right: "right-[-152px]", bottom: "bottom-[-79px]", src: photos[4]},
        {size: "size-6", right: "right-[-247px]", bottom: "bottom-[-22px]", src: photos[5]},
        {size: "size-6", right: "right-[-117px]", bottom: "bottom-[-115px]", src: photos[6]},
        {size: "size-8", right: "right-[-200px]", bottom: "bottom-[-121px]", src: photos[7]},
    ]

    return (
        <div className={`absolute ${className}`}>
            {
                photoParam.map(({size, right, bottom,src}, index) => {
                    return(
                        <Avatar key={index} className={`${size} absolute ${right} ${bottom}`}>
                            <AvatarImage src={src ?? defaultAvatar}/>
                        </Avatar>
                    )
                })
            }
        </div>
    )
}