import {createForm} from "effector-forms";
import {sample} from "effector";
import {$sessionUser} from "entities/session";
import {profileEditPageGate} from "pages/profile-edit-page/model/common";

interface IProfileEditForm {
    userName: string;
    firstName: string;
    birthDate: Date | null;
    gender: string | null;
    height: number | null;
    weight: number | null;
}

export const $profileEditForm = createForm<IProfileEditForm>({
    fields: {
        userName: {
            init: ""
        },
        firstName: {
            init: ""
        },
        birthDate: {
            init: null
        },
        gender: {
            init: null
        },
        height: {
            init: null
        },
        weight: {
            init: null
        }
    }
})

sample({
    //@ts-expect-error
    source: $sessionUser,
    clock: profileEditPageGate.open,
    //@ts-expect-error
    fn: (profileData) => ({
        userName: profileData?.userName,
        firstName: profileData?.firstName,
        birthDate: profileData?.birthDate
    }),
    target: $profileEditForm.setInitialForm
})