import * as React from "react"
import {FC} from "react";
import {format} from "date-fns"
import {CalendarIcon} from "lucide-react"
import {cn} from "shared/lib";
import {Button, Calendar, Label, Popover, PopoverContent, PopoverTrigger, Skeleton} from "shared/components";

interface IDatePicker {
    className?: string;
    placeholder?: string;
    date: Date | null;
    onSelect: (arg0: Date) => void;
    pending?: boolean;
    label?: string;
}

export const DatePicker: FC<IDatePicker> = ({
                                                className,
                                                placeholder = "Выберите дату",
                                                date,
                                                onSelect = () => {},
                                                pending = false,
                                                label
                                            }) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div className={`${className}`}>
                    {label && <Label>{label}</Label>}
                    <Button
                        variant={"outline"}
                        className={cn(
                            `w-full justify-start text-left font-normal`,
                            !date && "text-muted-foreground"
                        )}
                    >
                        <CalendarIcon/>
                        {date ? format(date, "dd/MM/yyyy") : <span>{placeholder}</span>}
                    </Button>
                </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date || undefined}
                    onSelect={(selectDate) => {
                        if (selectDate) onSelect(selectDate)
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}