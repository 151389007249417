import {TgAuthorizationButton} from "features/tg-authorization-button";
import {CatDark, LogoImg, PageWrapper, TypographyBody, TypographyH1} from "shared/components";

export const AuthorizationPage = () => {
    return (
        <PageWrapper>
            <div className="flex flex-col h-full p-2">
                <div className="flex flex-col gap-10 z-20">
                    <LogoImg/>
                    <div className="flex flex-col gap-8 z-20">
                        <TypographyH1 className="text-white text-5xl" >Салют, игрок!</TypographyH1>
                        <TypographyBody className="text-white text-base" muted={true}>Мы помогаем игрокам найти подходящие события,
                            зарегистрироваться на них и общаться с другими участниками.</TypographyBody>
                    </div>
                </div>
                <TgAuthorizationButton className="w-full mt-auto flex justify-center z-20" botName="PlatformUfa_bot" dataOnauth="handleTelegramAuth"/>
                <CatDark className="absolute top-[20%] inset-0 w-full h-full object-cover z-10"/>
            </div>
        </PageWrapper>
    )
}