import React, {ReactNode} from "react";
import {telegramPaddings} from "shared/lib/telegram-paddings";

interface IPageWrapper {
    children: ReactNode;
    backgroundColor?: string;
}

export const PageWrapper: React.FC<IPageWrapper> = ({
                                                        children,
                                                        backgroundColor = "bg-slate-800",
                                                    }) => {

    const {paddingTop} = telegramPaddings()

    return (
        <div className={`h-screen flex justify-center z-0 relative overflow-hidden ${backgroundColor}`}>
            <div className={`h-screen container px-2 pb-12 overflow-y-auto overflow-x-hidden`} style={{paddingTop: paddingTop}}>
                {children}
            </div>
        </div>
    )
}