import React from "react";
import {
    Avatar,
    AvatarImage,
    TypographyCaption,
    TypographyLabel
} from "shared/components";
import defaultAvatar from "shared/assets/default-avatar.svg"
import {DEFAULT, FULL} from "./constants";

interface IUserAvatar {
    type?: "default" | "full" | "edit";
    avatarSize?: "size-10" | "size-16";
    onClick?: () => void;
    photoUrl?: string;
    firstName?: string;
    userName?: string;
    theme?: "dark" | "light"
}

export const UserAvatar: React.FC<IUserAvatar> = ({
                                                      type = DEFAULT,
                                                      avatarSize = "size-10",
                                                      onClick,
                                                      userName,
                                                      firstName ,
                                                      photoUrl,
                                                      theme = "light"
                                                  }) => {

    let avatarBlockView
    switch (type) {
        case DEFAULT:
            avatarBlockView =
                <TypographyLabel className={`${theme === "light" ? "text-white" : "text-black"} text-sm`}>{userName ? `@${userName}` : firstName}</TypographyLabel>
            break;
        case FULL:
            avatarBlockView = <div>
                <TypographyLabel className={`${theme === "light" ? "text-white" : "text-black"} text-sm`} >{firstName}</TypographyLabel>
                {userName && <TypographyCaption className={`${theme === "light" ? "text-white" : "text-black"} text-xs`} muted={true}>{`@${userName}`}</TypographyCaption>}
            </div>
    }

    return (
        <div className="flex items-center gap-2" onClick={onClick}>
            <Avatar className={`${avatarSize}`}>
                <AvatarImage src={photoUrl ?? defaultAvatar}/>
            </Avatar>
            {avatarBlockView}
        </div>
    )
}