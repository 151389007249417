import {LocationPropertyDto} from "shared/api/generate/data-contracts";
import {toDateTimeFormat} from "shared/lib";
import {IEventCreateForm} from "./types";

export const buildLocationPropertyData = (locationProperties: LocationPropertyDto[]) => {

    if (!locationProperties?.length) {
        return {};
    }

    return locationProperties.reduce(
        (acc: Record<string, LocationPropertyDto[]>, property: LocationPropertyDto) => {
            if (property.group) {
                if (!acc[property.group]) {
                    acc[property.group] = [];
                }
                acc[property.group].push(property);
            } else {
                acc[property.code] = [property];
            }
            return acc;
        },
        {}
    );
}

export const buildCreateEventRequestData = ({
                                                sportCode,
                                                date,
                                                time,
                                                duration,
                                                locationName,
                                                locationAddress,
                                                locationDescription,
                                                locationCoordinates,
                                                locationProperties,
                                                locationPhotos,
                                                seats,
                                                ownerIsPlaying,
                                                ageFrom,
                                                ageTo,
                                                cost,
                                                isFree
                                            }: IEventCreateForm) => {

    const formData = new FormData();

    formData.append("sportCode", sportCode ?? "")
    formData.append("dateTime", toDateTimeFormat(date, time))
    formData.append("duration", duration?.toString() ?? "")
    formData.append("locationName", locationName ?? "")
    formData.append("locationAddress", locationAddress ?? "")
    formData.append("locationDescription", locationDescription ?? "")
    formData.append("locationCoordinates", locationCoordinates ?? "")
    formData.append("locationProperties", Object.values(locationProperties).filter(properties => Boolean(properties))?.toString() ?? "")
    formData.append("seats", seats?.toString() ?? "")
    formData.append("ownerIsPlaying", ownerIsPlaying?.toString() ?? "")
    formData.append("sportCode", sportCode?.toString() ?? "")
    formData.append("ageFrom", ageFrom?.toString() ?? "")
    formData.append("ageTo", ageTo?.toString() ?? "")
    formData.append("cost", (isFree ? 0 : cost)?.toString() ?? "")

    locationPhotos?.forEach((file) => {
        formData.append(`locationPhotos[]`, file)
    });

    Object.values(locationProperties).filter(properties => Boolean(properties)).forEach((propertie) => {
        formData.append(`locationProperties[]`, propertie ?? "")
    })

    return formData
}