/**
 * Преобразует строку с датой в формате ISO 8601 в объект с датой, временем и днем недели.
 *
 * @param isoDate - Строка с датой и временем в формате ISO 8601 (например, "2024-12-21T19:00:00.000Z").
 * @param format - Формат вывода даты. Возможные значения:
 *   - "DD.MM.YYYY" (например, "21.12.2024")
 *   - "D Mmm" (например, "21 дек")
 *   - "HH:mm" (например, "19:00")
 *   - "weekday" (например, "суббота")
 *
 * @returns Объект, содержащий дату, время и день недели в указанном формате.
 */

export function dateTimeTransform(isoDate: string, format: string = "DD.MM.YYYY") {
    if (!isoDate) {
        throw new Error("Передайте корректное значение в формате: isoDate");
    }

    const date = new Date(isoDate);

    const day = date.getDate();
    const paddedDay = String(day).padStart(2, "0");
    const monthIndex = date.getMonth();
    const paddedMonth = String(monthIndex + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Массив с названиями месяцев на русском
    const months = [
        "янв", "фев", "мар", "апр", "май", "июн",
        "июл", "авг", "сен", "окт", "ноя", "дек"
    ];

    // Массив с названиями дней недели на русском
    const weekdays = [
        "воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"
    ];

    // День недели
    const weekday = weekdays[date.getDay()];

    // Обрабатываем формат
    let formattedDate = "";

    switch (format) {
        case "DD.MM.YYYY":
            formattedDate = `${paddedDay}.${paddedMonth}.${year}`;
            break;
        case "D Mmm":
            formattedDate = `${day} ${months[monthIndex]}`;
            break;
        case "HH:mm":
            formattedDate = `${hours}:${minutes}`;
            break;
        default:
            throw new Error("Неизвестный формат");
    }

    return {
        date: formattedDate,
        time: `${hours}:${minutes}`,
        weekday
    };
}