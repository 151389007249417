import React, {FC} from "react";
import {Avatar, AvatarImage, Skeleton, TypographyBody} from "shared/components";
import defaultAvatar from "shared/assets/default-avatar.svg";

interface IAvatarGroup {
    avatarSize?: string;
    photos: string[];
    pending?: boolean;
}

export const AvatarGroup: FC<IAvatarGroup> = ({
                                                  avatarSize = "size-10",
                                                  photos,
                                                  pending = false
                                              }) => {
    if (pending) {
        return (
            <div className="flex">
                {Array.of(1, 2, 3).map((skeleton, index) => {
                    return (
                        <Avatar
                            className={`${avatarSize} rounded-full border-2 border-white bg-center ${(index > 0) ? "-ml-5" : ""}`}>
                            <Skeleton
                                className={`${avatarSize} rounded-full border-2 border-white bg-center`}></Skeleton>
                        </Avatar>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="flex">
            {
                photos.slice(0, 3).map((photo, index) => {
                    return (
                        <Avatar
                            className={`${avatarSize} rounded-full border-2 border-white bg-[url('shared/assets/default-avatar.svg')] bg-center ${(index > 0) ? "-ml-5" : ""}`} key={photo}>
                            {
                                (photos.length > 3 && index > 1)
                                    ? <div className={`w-full bg-gray-300 flex flex-col items-center justify-center`}>
                                        <TypographyBody className="text-black">{`+${photos.length - 2}`}</TypographyBody></div>
                                    : <AvatarImage src={photo ?? defaultAvatar}
                                                   className="bg-[url('shared/assets/default-avatar.svg')]"/>
                            }
                        </Avatar>
                    )
                })
            }
        </div>
    )
}