import React, {FC} from "react";

interface ITypography {
    children?: string;
    className?: string;
    textColor?: string;
    muted?: boolean;
}

export const TypographyH1: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        muted
                                                    }) => {
    return (
        <h1 className={`font-inter font-semibold leading-none tracking-[-0.576px] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </h1>
    )
}

export const TypographyH2: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        muted
                                                    }) => {
    return (
        <h2 className={`font-inter font-semibold leading-[1.2] tracking-[-0.1px] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </h2>
    )
}

export const TypographyH3: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        muted
                                                    }) => {
    return (
        <h3 className={`font-inter font-medium leading-[1.5] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </h3>
    )
}

export const TypographyH4: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        muted
                                                    }) => {
    return (
        <h4 className={`font-inter font-medium leading-[1.2] tracking-[-0.225px] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </h4>
    )
}

export const TypographyH5: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        muted
                                                    }) => {
    return (
        <h5 className={`font-inter font-medium leading-[1.4] tracking-[-0.1px] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </h5>
    )
}

export const TypographyBody: React.FC<ITypography> = ({
                                                          children,
                                                          className,
                                                          muted
                                                   }) => {
    return (
        <p className={`font-inter font-normal leading-[1.25] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </p>
    )
}

export const TypographyBodySmall: FC<ITypography> = ({
                                                         children,
                                                         className,
                                                         muted
                                }) => {
    return (
        <p className={`font-inter font-normal leading-[1.28571] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </p>
    )
}

export const TypographyCaption: FC<ITypography> = ({
                                                       children,
                                                       className,
                                                       muted
                                                 }) => {
    return (
        <p className={`font-inter font-medium leading-[1.66667] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </p>
    )
}

export const TypographySwitch: FC<ITypography> = ({
                                                     children,
                                                     className,
                                                     muted
                                                 }) => {
    return (
        <p className={`font-inter font-normal leading-[1.5] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </p>
    )
}

export const TypographyLabel: FC<ITypography> = ({
                                                      children,
                                                      className,
                                                      muted
                                                  }) => {
    return (
        <p className={`font-inter text-[14px] font-semibold leading-[1.42857] ${className} ${muted ? "opacity-80" : ""}`}>
            {children}
        </p>
    )
}