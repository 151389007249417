import {createGate} from "effector-react";
import {createEvent, createStore, sample} from "effector";
import {$events, attachGetEventsFx} from "entities/events";
import {redirect} from "atomic-router";
import {$sportTypes, attachedGetSportsTypesFx} from "entities/sport-types";
import {eventPageRoute} from "shared/routes";
import {$sportFilterForm} from "./form";
import {TEventsGroupedByDate} from "pages/events-page/model/types";
import {ISportTypes} from "entities/sport-types/types";

export const eventsPageGate = createGate()
export const goToEvent = createEvent<{ id: string }>()
export const $eventsGroupedByDate = createStore<TEventsGroupedByDate | null>(null)
export const $sportIdFilterItems = createStore<ISportTypes[] | null>(null)

sample({
    clock: eventsPageGate.open,
    target: attachedGetSportsTypesFx
})

sample({
    clock: $sportTypes,
    target: $sportIdFilterItems
})

sample({
    //@ts-expect-error
    source: $sportFilterForm.$values,
    clock: eventsPageGate.open,
    fn: (sport,_) => sport.selectedSportTypes,
    target: attachGetEventsFx
})


sample({
    //@ts-expect-error TODO target ожидает {sports: string}, но тогда мы не сможем передать несколько видов спотра, надо уточнить
    clock: $sportFilterForm.fields.selectedSportTypes.$value,
    fn: (sport) => sport,
    target: attachGetEventsFx
})

sample({
    clock: $events.updates,
    fn: (events): TEventsGroupedByDate => {
        //@ts-expect-error TODO Разобраться с типом
        return events.reduce((acc: TEventsGroupedByDate, event): TEventsGroupedByDate => {
            const date = new Date(event?.dateTime).toISOString().split("T")[0]

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push(event);

            return acc
        },{})
    },
    target: $eventsGroupedByDate
})

redirect({
    clock: goToEvent,
    params: (payload) => ({ id: payload.id }),
    route: eventPageRoute,
});