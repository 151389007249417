import {FC} from "react";
import {ISvgComponent} from "shared/components/img-components/types";

export const CloseButtonIcon: FC<ISvgComponent> = ({
                                                       width = 17,
                                                       height = 16,
                                                       className
                                                   }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 17 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 4L4.5 12" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.5 4L12.5 12" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}