import {FC} from "react";
import {ISvgComponent} from "shared/components/img-components/types";

export const DropdownIcon: FC<ISvgComponent> = ({
                                                      width = "16",
                                                      height = "16",
                                                      className
                                                  }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}