import {useGate, useUnit} from "effector-react";
import {useState} from "react";
import {useForm} from "effector-forms";
import {
    AddressSearchInput,
    Button,
    CheckboxWithLabel,
    DatePicker,
    InputWithMeasureUnit,
    EventContentContainer,
    PageHeader,
    Input,
    InputWithLabel,
    NumberInput,
    PageWrapper,
    SelectDrawer,
    SwitchBlock,
    TextareaWithLabel,
    TimePicker,
    TypographyH2,
    TypographyBodySmall,
    TypographyCaption,
} from "shared/components";
import {renderLocationProperties, $eventCreateForm, $locationProperties, $sportIdFieldItems, EventCreatePageGate, formValidate} from "./model";
import {PhotosBlock} from "./ui/photos-block";

export const EventCreatePage = () => {

    useGate(EventCreatePageGate)
    const [locationPropertyChecked, setLocationPropertyChecked] = useState<{[key: string]: boolean}>({})
    const {fields} = useForm($eventCreateForm)
    const [sportIdFieldItems, locationProperties] = useUnit([$sportIdFieldItems, $locationProperties])

    console.log(fields.locationPhotos.value)

    return (
        <PageWrapper>
            <div className="flex flex-col gap-2">
                <PageHeader>
                    <TypographyH2 className="w-1/2 text-[40px] text-black">Создать событие</TypographyH2>
                </PageHeader>
                <EventContentContainer title="Вид игры">
                    <div className="flex flex-col gap-4">
                        <SelectDrawer
                            options={sportIdFieldItems?.map(({icon, code, name}) => ({
                                icon,
                                name,
                                value: code
                            }))}
                            onSelect={({value}) => fields.sportCode.onChange(value)}
                            placeholder="Выберите из списка"
                            value={fields.sportCode.value}
                        />
                    </div>
                </EventContentContainer>
                <EventContentContainer title="Дата и время">
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-4 ">
                            <DatePicker className="w-1/2" date={fields.date.value} onSelect={fields.date.onChange}/>
                            <TimePicker className="w-1/2" time={fields.time.value} onChange={fields.time.onChange}/>
                        </div>
                        <InputWithMeasureUnit label="Длительность" onChange={fields.duration.onChange}
                                              measureUnit="мин"/>
                    </div>
                </EventContentContainer>
                <EventContentContainer title="Место">
                    <div className="flex flex-col gap-4">
                        <InputWithLabel placeholder="Например, 'Спотрзал'" label="Название площадки"
                                        onChange={fields.locationName.onChange}/>
                        <AddressSearchInput placeholder="Введите адрес места проведения" label="Адрес"
                                            onChange={fields.locationAddress.onChange}/>
                        <TextareaWithLabel placeholder="Опишите важные детали" label="Описание"
                                           onChange={fields.locationDescription.onChange}/>
                        <PhotosBlock onChange={fields.locationPhotos.onChange} photos={fields.locationPhotos.value}/>
                    </div>
                </EventContentContainer>
                <EventContentContainer title="Количество игроков">
                    <div className="flex flex-col gap-4">
                        <NumberInput label="Количество мест" onChange={fields.seats.onChange}/>
                        <SwitchBlock checked={fields.ownerIsPlaying.value}
                                     onCheckedChange={fields.ownerIsPlaying.onChange}>
                            <div>
                                <TypographyCaption className="text-black text-sm">Я тоже буду играть</TypographyCaption>
                                <TypographyBodySmall className="text-slate-500 text-sm">добавить меня участником
                                    игры</TypographyBodySmall>
                            </div>
                        </SwitchBlock>
                        {fields.isAgePeriod.value
                            ? (<div className="flex items-end gap-2">
                                <NumberInput label="Допустимый возраст" onChange={fields.ageFrom.onChange}/>
                                <div className="h-8">—</div>
                                <NumberInput onChange={fields.ageTo.onChange}/>
                            </div>)
                            : <NumberInput label="Допустимый возраст" onChange={fields.ageFrom.onChange}/>
                        }
                        <CheckboxWithLabel label="Возрастной период" checked={fields.isAgePeriod.value}
                                           onCheckedChange={fields.isAgePeriod.onChange}/>
                    </div>
                </EventContentContainer>
                <EventContentContainer title="Стоимость" subTitle="за участия одного игрока">
                    <SwitchBlock label={fields.isFree.value ? "Бесплатно" : ""} checked={fields.isFree.value}
                                 onCheckedChange={fields.isFree.onChange}>
                        {!fields.isFree.value &&
                            <Input type="number" onChange={(event) => fields.cost.onChange(Number(event.target.value))}
                                   className="border-none rounded-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 h-7 -my-2 p-0"/>}
                    </SwitchBlock>
                </EventContentContainer>
                <EventContentContainer title="Дополнительные сведения">
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">

                            {renderLocationProperties({
                                locationProperties,
                                locationPropertyChecked,
                                setLocationPropertyChecked,
                                fields,
                            })}

                        </div>
                        <Button onClick={() => formValidate()}>Создать</Button>
                    </div>
                </EventContentContainer>
            </div>
        </PageWrapper>
    )
}