import React, {useState} from "react";
import {Button, Input, Label} from "shared/components/lib-ui";
import {Skeleton} from "shared/components/lib-ui/skeleton";

interface NumberInputProps {
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    onChange?: (value: number) => void;
    label?: string;
    className?: string;
    pending?: boolean;
}

export const NumberInput: React.FC<NumberInputProps> = ({
                                                            min = Number.MIN_SAFE_INTEGER,
                                                            max = Number.MAX_SAFE_INTEGER,
                                                            step = 1,
                                                            defaultValue = 0,
                                                            onChange,
                                                            label,
                                                            className,
                                                            pending = false,
                                                        }) => {
    const [value, setValue] = useState<number>(defaultValue);

    const handleIncrease = () => {
        const newValue = Math.min(value + step, max);
        setValue(newValue);
        onChange?.(newValue);
    };

    const handleDecrease = () => {
        const newValue = Math.max(value - step, min);
        setValue(newValue);
        onChange?.(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(e.target.value, 10);
        if (!isNaN(inputValue)) {
            const clampedValue = Math.max(Math.min(inputValue, max), min);
            setValue(clampedValue);
            onChange?.(clampedValue);
        }
    };

    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="flex items-center gap-0 w-full">
                <Button className="rounded-none rounded-l-lg" onClick={handleDecrease} variant="outline">
                    -
                </Button>
                <Input
                    value={value}
                    onChange={handleInputChange}
                    className="w-full text-center rounded-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                />
                <Button className="rounded-none rounded-r-lg" onClick={handleIncrease} variant="outline">
                    +
                </Button>
            </div>
        </div>

    );
};