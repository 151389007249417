import React, {FC, ReactNode} from "react";
import {TypographySwitch} from "shared/components";
import {Switch} from "shared/components/lib-ui/switch";
import {Skeleton} from "shared/components/lib-ui/skeleton";

interface ISwitchBlock {
    label?: string | null;
    checked: boolean;
    onCheckedChange?: (v: boolean) => any;
    children?: ReactNode;
    pending?: boolean;
}

export const SwitchBlock: FC<ISwitchBlock> = ({
                                                  label,
                                                  checked,
                                                  onCheckedChange ,
                                                  children,
                                                  pending = false
}) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className="rounded-lg border border-[#CBD5E1] bg-white p-2">
            <div className="flex justify-between items-center">
                {label ? (<TypographySwitch className="text-black text-sm">{label}</TypographySwitch>) : children}
                <Switch checked={checked} onCheckedChange={onCheckedChange}/>
            </div>
            {label && children}
        </div>
    )
}