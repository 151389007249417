/**
 * Объединяет дату и время в формат @format date-time
 * @param date - Дата в формате "YYYY-MM-DDTHH:mm:ss.sssZ"
 * @param time - Время в формате "HH:mm"
 * @returns Строка в формате "YYYY-MM-DDTHH:mm:ss.sssZ"
 */

export function toDateTimeFormat(date: Date | null, time: string = "00:00"): string {

    if (!date) {
        throw Error("Передайте дату")
    }
    // Разбираем дату и создаём объект Date
    const datePart = new Date(date);

    // Извлекаем часы и минуты из времени
    const [hours, minutes] = time.split(":").map(Number);

    // Устанавливаем часы и минуты в объект Date
    datePart.setUTCHours(hours, minutes, 0, 0);

    // Преобразуем объект Date обратно в строку в формате ISO
    return datePart.toISOString();
}
