import {FC} from "react";
import {dateTimeTransform} from "shared/lib";
import {TypographyCaption} from "shared/components";
import {DottedLine} from "shared/components/img-components/dotted-line";

interface IBasicInformation {
    dateTime?: string;
    duration?: number;
    ageFrom?: number;
    ageTo?: number;
    cost?: number;
}

export const BasicInformation: FC<IBasicInformation> = ({
                                                            dateTime,
                                                            duration= "неопределено",
                                                            ageFrom = "неопределено",
                                                            ageTo = "неопределено",
                                                            cost = "неопределено"
}) => {

    const getDateTime = (dateTimeValue: string | undefined) => {
        if (dateTimeValue !== undefined){
            const {date, time} = dateTimeTransform(dateTimeValue, "D Mmm")
            return `${date}, ${time}`
        }
        return "неопределено"
    }

    const basicInformation = [
        {title: "Дата и время", value: getDateTime(dateTime)},
        {title: "Длительность", value: `${duration} мин`},
        {title: "Допустимый возраст", value: `${ageFrom ? ("c " + ageFrom) : ''} ${ageTo ? ("до " + ageTo) : ''}`},
        {title: "Стоимость", value: `${cost}₽`},
    ]

    return (
        <div className="flex flex-col gap-2">
            {
                basicInformation.map(el => {
                    return (
                        <div className="flex gap-2 items-center justify-between">
                            <TypographyCaption className="text-black text-sm" muted={true}>{el.title}</TypographyCaption>
                            <DottedLine/>
                            <TypographyCaption className="text-black text-sm">{el.value}</TypographyCaption>
                        </div>
                    )
                })
            }
        </div>
    )
}