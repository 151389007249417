import React, {FC} from "react";
import {Checkbox} from "shared/components/lib-ui/checkbox";
import {Label, Skeleton} from "shared/components";

interface ICheckboxWithLabel {
    label: string;
    checked: boolean;
    onCheckedChange: (v: boolean) => boolean;
    pending?: boolean;
}

export const CheckboxWithLabel: FC<ICheckboxWithLabel> = ({
                                                              label,
                                                              checked,
                                                              onCheckedChange,
                                                              pending = false
                                                          }) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className="flex items-center space-x-2">
            <Checkbox id="terms" checked={checked} onCheckedChange={onCheckedChange}/>
            <Label>{label}</Label>
        </div>
    )
}