import {createGate} from "effector-react";
import {attach, createStore, sample} from "effector";
import {attachedGetSportsTypesFx} from "entities/sport-types";
import {getLocationPropertyFX} from "shared/api";
import {LocationPropertyDto} from "shared/api/generate/data-contracts";
import {buildLocationPropertyData} from "./lib";

const attachedGetLocationPropertyFX = attach({
    effect: getLocationPropertyFX
})

export const EventCreatePageGate = createGate()

export const $locationProperties = createStore<{[key: string]: LocationPropertyDto[]} | null>(null)

sample({
    clock: EventCreatePageGate.open,
    target: attachedGetSportsTypesFx
})

sample({
    clock: EventCreatePageGate.open,
    target: attachedGetLocationPropertyFX,
})

sample({
    clock: attachedGetLocationPropertyFX.doneData,
    fn: buildLocationPropertyData,
    target: $locationProperties
});