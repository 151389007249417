import {attach, restore, sample} from "effector";
import {getUserFx, updateUserFx} from "shared/api/querys-effects";
import {UserDto} from "shared/api/generate/data-contracts";

export const attachedGetUserFx = attach({effect: getUserFx})
export const attachedUpdateUserFx = attach({effect: updateUserFx})

export const $user = restore<null | UserDto>(attachedGetUserFx.doneData, null)

sample({
    clock: attachedGetUserFx.doneData,
    target: $user
})