import React, {FC} from "react";
import {Input, Label, Skeleton, TypographyBody} from "shared/components";

interface IInputWithMeasureUnit {
    className?: string;
    label?: string;
    placeholder?: string;
    onChange: (arg1: number) => void;
    measureUnit: string;
    pending?: boolean;
}

export const InputWithMeasureUnit: FC<IInputWithMeasureUnit> = ({
                                                                    className,
                                                                    label,
                                                                    placeholder,
                                                                    onChange,
                                                                    measureUnit,
                                                                    pending = false
                                                                }) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="relative">
                <Input placeholder={placeholder} type="number"
                       onChange={(event) => onChange(Number(event.target.value))}/>
                <span
                    className="pointer-events-none absolute inset-y-0 end-0 flex items-center justify-center pe-3 peer-disabled:opacity-50">
                    <TypographyBody className="text-black">{measureUnit}</TypographyBody>
                </span>
            </div>
        </div>
    );
}