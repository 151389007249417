import {createStore, sample, attach} from "effector";
import {ISportTypes} from "entities/sport-types/types";
import {getSportsTypesFx} from "shared/api";
import {icons} from "shared/constants";

export const attachedGetSportsTypesFx = attach({effect:getSportsTypesFx})

export const $sportTypes = createStore<ISportTypes[] | null>(null)

sample({
    clock: attachedGetSportsTypesFx.doneData,
    //@ts-ignore TODO разобраться с типизацией
    fn: (sportsTypes) => sportsTypes.map((sportType) => ({...sportType, icon: icons[sportType.code]})),
    target: $sportTypes
})