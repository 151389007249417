import React from "react";
import {RouteInstance} from "atomic-router";
import {
    authorizationPageRoute,
    eventCreatePageRoute, eventPageRoute,
    eventsPageRoute,
    mainPageRoute, onboardingPageRoute, profileEditPageRoute,
    userProfilePageRoute
} from "shared/routes";
import {MainPage} from "pages/main-page";
import {AuthorizationPage} from "pages/аuthorization-page";
import {UserProfilePage} from "pages/user-profile-page";
import {EventsPage} from "pages/events-page";
import {OnboardingPage} from "pages/onboarding-page";
import {EventCreatePage} from "pages/event-create-page";
import {EventPage} from "pages/event-page";
import {ProfileEditPage} from "pages/profile-edit-page/profile-edit-page";

export type IRoutes = {
    pageName: string;
    path: string;
    route: RouteInstance<any>;
    component: React.FC;
};

export const routes: IRoutes[] = [
    { pageName: "Главная страница", path: '/', route: mainPageRoute, component: MainPage },
    { pageName: "Страница авторизации", path: '/authorization', route: authorizationPageRoute, component: AuthorizationPage },
    { pageName: "Профиль пользователя", path: '/profile', route: userProfilePageRoute, component: UserProfilePage },
    { pageName: "События", path: '/events', route: eventsPageRoute, component: EventsPage },
    { pageName: "Ознакомление", path: '/onboarding', route: onboardingPageRoute, component: OnboardingPage},
    { pageName: "Создание события", path: '/create-event', route: eventCreatePageRoute, component: EventCreatePage},
    { pageName: "Событие", path: '/event/:id', route: eventPageRoute, component: EventPage},
    { pageName: "Редактирование профиля", path: '/edit-profile', route: profileEditPageRoute, component: ProfileEditPage},
]




