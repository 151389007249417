import {FC} from "react";
import {ISvgComponent} from "shared/components/img-components/types";

export const UploadIcon: FC<ISvgComponent> = ({
                                                      width = "32",
                                                      height = "32",
                                                      className
                                                  }) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28 16V25.3333C28 26.0406 27.719 26.7189 27.219 27.219C26.7189 27.719 26.0406 28 25.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V6.66667C4 5.95942 4.28095 5.28115 4.78105 4.78105C5.28115 4.28095 5.95942 4 6.66667 4H16"
                stroke="#CBD5E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21.334 6.6665H29.334" stroke="#CBD5E1" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M25.334 2.6665V10.6665" stroke="#CBD5E1" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M12.0007 14.6668C13.4734 14.6668 14.6673 13.4729 14.6673 12.0002C14.6673 10.5274 13.4734 9.3335 12.0007 9.3335C10.5279 9.3335 9.33398 10.5274 9.33398 12.0002C9.33398 13.4729 10.5279 14.6668 12.0007 14.6668Z"
                stroke="#CBD5E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M28 19.9999L23.8853 15.8853C23.3853 15.3853 22.7071 15.1045 22 15.1045C21.2929 15.1045 20.6147 15.3853 20.1147 15.8853L8 27.9999"
                stroke="#CBD5E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}