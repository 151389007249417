import React from "react";
import {Label, RadioGroup, RadioGroupItem, SwitchBlock} from "shared/components";
import {IRenderLocationProperties} from "./types";

export function renderLocationProperties({
                                             locationProperties,
                                             locationPropertyChecked,
                                             setLocationPropertyChecked,
                                             fields,
                                         }: IRenderLocationProperties) {
    return (
        locationProperties &&
        Object.keys(locationProperties).map((key) => {
            const properties = locationProperties[key];

            if (properties.length > 1) {
                const propertyGroupCode = properties[0].code;
                const isOpened = locationPropertyChecked[propertyGroupCode];

                return (
                    <SwitchBlock
                        key={key}
                        label={properties[0].groupName || ""}
                        checked={isOpened}
                        onCheckedChange={(value) => {
                            setLocationPropertyChecked((prev) => ({
                                ...prev,
                                [propertyGroupCode]: value,
                            }));

                            if (!value) {
                                const groupValues = properties.reduce((acc: Record<string, string | null>, property) => {
                                    acc[property.code] = null;
                                    return acc;
                                }, {});
                                fields.locationProperties.onChange({
                                    ...fields.locationProperties.value,
                                    ...groupValues,
                                });
                            }
                        }}
                    >
                        {isOpened &&
                            <RadioGroup
                                className="mt-6 gap-4"
                                onValueChange={(value) =>
                                    fields.locationProperties.onChange({
                                        ...fields.locationProperties.value,
                                        [key]: value,
                                    })
                                }
                            >
                                {
                                    properties.map((property) => (
                                        <div key={property.code} className="flex items-center space-x-2">
                                            <RadioGroupItem
                                                value={property.code}
                                                checked={fields.locationProperties.value[key] === property.code}
                                            />
                                            <Label htmlFor={`radio-${property.code}`}>{property.name}</Label>
                                        </div>
                                    ))}
                            </RadioGroup>
                        }
                    </SwitchBlock>
                );
            } else {
                const singleProperty = properties[0];

                return (
                    <SwitchBlock
                        key={key}
                        label={singleProperty.name}
                        checked={Boolean(fields.locationProperties.value[key])}
                        onCheckedChange={(value) => {
                            if (!value) {
                                const groupValues = properties.reduce((acc: Record<string, string | null>) => {
                                    acc[key] = null;
                                    return acc;
                                }, {});
                                fields.locationProperties.onChange({
                                    ...fields.locationProperties.value,
                                    ...groupValues,
                                });
                            } else {
                                fields.locationProperties.onChange({
                                    ...fields.locationProperties.value,
                                    [key]: singleProperty.code,
                                });
                            }
                        }}
                    />
                );
            }
        })
    );
}
