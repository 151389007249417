import React, {useEffect, useState} from "react";
import {
    Button,
    Drawer,
    DrawerClose,
    DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle,
    DrawerTrigger,
    DropdownIcon,
    Label, Skeleton,
} from "shared/components";

interface ISelectDrawerProps {
    options?: TOption[];
    onSelect?: (option: TOption) => void;
    placeholder?: string;
    label?: string;
    value: TOption['value'] | null;
    pending?: boolean;
    title?: string;
    description?: string;
}

export type TOption = {
    value: string,
    icon?: string | number,
    name: string,
}

export const SelectDrawer: React.FC<ISelectDrawerProps> = ({
                                                               options,
                                                               onSelect = () => {
                                                               },
                                                               placeholder = "Выберите из списка",
                                                               title = "Выберите из списка",
                                                               description,
                                                               label,
                                                               value = null,
                                                               pending = false
                                                           }) => {
    const [selected, setSelected] = useState<TOption | null>(null);

    useEffect(() => {
        const selectedOption = options?.find(item => item.value === value);
        setSelected(selectedOption || null);
    }, [value, options]);

    const handleSelect = (option: TOption) => {
        setSelected(option);
    };

    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <Drawer>
            <DrawerTrigger asChild>
                <div className="flex flex-col gap-2">
                    {label && <Label>{label}</Label>}
                    <Button variant="outline">
                        {
                            selected
                                ? <div className="flex w-full justify-between items-center">
                                    <div className="flex items-center gap-2">{selected.icon}{selected.name}</div>
                                    <DropdownIcon/></div>
                                : <div
                                    className="flex w-full justify-between items-center text-slate-400">{placeholder}<DropdownIcon/>
                                </div>
                        }
                    </Button>
                </div>
            </DrawerTrigger>
            <DrawerContent className="px-4 py-6 gap-6">
                <DrawerHeader className="flex p-0">
                    <DrawerTitle>{title}</DrawerTitle>
                    <DrawerDescription>{description}</DrawerDescription>
                </DrawerHeader>
                <ul className="flex flex-col gap-4">
                    {options?.map((option) => (
                        <li key={option.value} className="flex flex-col">
                            <DrawerClose asChild>
                                <div
                                    className="flex gap-2 items-center"
                                    onClick={() => {
                                        handleSelect(option)
                                        onSelect(option)
                                    }}>
                                    {option.icon}{option.name}
                                </div>
                            </DrawerClose>
                        </li>
                    ))}
                </ul>
            </DrawerContent>
        </Drawer>
    )
};