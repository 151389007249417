import {FC} from "react";
import {DeleteIcon, PhotoInput} from "shared/components";

interface IPhotosBlock {
    onChange: (v: File[] | null) => File[] | null;
    photos: File[] | null;
}

export const PhotosBlock: FC <IPhotosBlock> = ({
                                                   onChange = () => {},
                                                   photos= null
}) => {

    const setPhotoInArray= (newPhoto: File) => {
        const newPhotosArray = photos ? [...photos, newPhoto] : [newPhoto]
        onChange(newPhotosArray)
    }

    const deletePhotoInArray= (deteledPhotoIndex: number) => {
        const newPhotosArray = photos ? (photos.filter((photo, index) => index !== deteledPhotoIndex)) : photos
        onChange(newPhotosArray)
    }

    return (
        <div className="flex gap-2 flex-wrap">
            {
                photos
                && photos.map((photo, index) => (
                        <div key={index} className="flex w-[96px] h-[72px] relative items-center justify-center bg-black rounded-lg">
                            <img
                                src={URL.createObjectURL(photo)}
                                alt={`Фото ${index + 1}`}
                            />
                            <button
                                className="absolute bottom-1 right-1 bg-black text-white rounded-full w-6 h-6 flex items-center justify-center"
                                onClick={() => deletePhotoInArray(index)}
                            >
                                <DeleteIcon/>
                            </button>
                        </div>
                    ))
            }
            <PhotoInput onChange={(value) => {
                if (value) {
                    setPhotoInArray(value)
                } else {
                    throw Error("Приложите файл")
                }
            }}/>
        </div>
    )
}