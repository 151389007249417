import {createEffect} from "effector";
import {api} from "shared/api/api-instances";
import {
    CreateDto, EventDto,
    LocationPropertyDto,
    SimpleEventDto,
    SportDto,
    TelegramBotDto,
    UserDto
} from "shared/api/generate/data-contracts";

export const getUsersFx = createEffect<void, UserDto[]>(() => {
    return fetch("https://platformufa.ru/api/users", {
        credentials: 'include'
    })
        .then(response => response.json())
})

export const getUserFx = createEffect<number, UserDto, Error>((userId) => {
    return fetch(`https://platformufa.ru/api/users/${userId}`, {
        credentials: 'include'
    })
        .then(response => response.json())
})

export const updateUserFx = createEffect<object, void, Error>((payload) => api.updateUser(payload))

export const getSessionFx = createEffect<void, UserDto, Error>(() => api.findTgSession())

export const loginFx = createEffect<TelegramBotDto, UserDto, Error>((payload) => api.signIn(payload))

export const getSportsTypesFx = createEffect<void, SportDto[], Error>(() => api.findAllSports())

export const getLocationPropertyFX = createEffect<void, LocationPropertyDto[]>(() => api.findAllPropertiesLocation())

export const createEventFx = createEffect<CreateDto, void>((data) => api.createEvent(data))

export const getEventsFx = createEffect<{sport: string}, SimpleEventDto[], Error>((payload)=> api.findAllEvents(payload))

export const getEventFx = createEffect<string, EventDto>((id) => api.findOneEvent(id))