import React, {FC} from "react";
import {Button, Label, Skeleton} from "shared/components";

interface IButtonSelect {
    selected: string | null;
    onSelect: (v: string | null) => string | null;
    items: TItem[];
    label?: string;
    pending?: boolean;
    className?: string;
}

type TItem = {
    value: string;
    name: string;
}

export const ButtonSelect: FC<IButtonSelect> = ({
                                                    selected= null,
                                                    onSelect,
                                                    items,
                                                    label,
                                                    pending= false,
                                                    className
                             }) => {

    if (pending) {
        return <Skeleton className="h-10 w-12"/>
    }

    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="flex gap-2 relative">
                {
                    items.map(item => {
                        return <Button variant={item.value === selected ? "default" : "outline"}
                                       onClick={() => onSelect(item.value)}>{item.name}</Button>
                    })
                }
            </div>
        </div>
    )
}