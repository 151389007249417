import {createStore, sample} from "effector";
import {attach} from "effector/compat";
import {getEventFx} from "shared/api";
import {EventDto} from "shared/api/generate/data-contracts";

export const attachGetEventFx = attach({effect: getEventFx})

export const $event = createStore<EventDto | null>(null)

sample({
    clock: attachGetEventFx.doneData,
    target: $event
})