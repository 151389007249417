import React, {FC} from "react";
import {Search} from "lucide-react";
import {Input, Label, Skeleton} from "shared/components";

interface IAddressSearchInput {
    label?: string;
    placeholder?: string;
    className?: string;
    onChange: (arg: string) => void;
    pending?: boolean;
}

export const AddressSearchInput: FC<IAddressSearchInput> = ({
                                                                label,
                                                                placeholder,
                                                                className,
                                                                onChange = () => {
                                                                },
                                                                pending = false
                                                            }) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="relative">
                <Input className="peer pe-9 ps-9 h-10" placeholder={placeholder} type="search"
                       onChange={(event) => onChange(event.target.value)}/>
                <div
                    className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-muted-foreground/80 peer-disabled:opacity-50">
                    <Search size={16} strokeWidth={2}/>
                </div>
            </div>
        </div>
    );
}