import {createStore, sample} from "effector";
import {attach} from "effector/compat";
import {getEventsFx} from "shared/api";
import {SimpleEventDto} from "shared/api/generate/data-contracts";

export const attachGetEventsFx = attach({effect: getEventsFx})

export const $events = createStore<SimpleEventDto[] | null>(null)

sample({
    clock: attachGetEventsFx.doneData,
    target: $events
})