import {Button, CatLight, TypographyBodySmall, TypographyH4} from "shared/components";
import {redirectToEventsPage} from "features/upcoming-events/model";

export const UpcomingEvents = () => {

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col gap-2 z-20">
                <TypographyH4 className="text-white text-xl">Ближайшее событие</TypographyH4>
                {/*//TODO поменять на нужный текстфилд*/}
                <TypographyBodySmall className="text-white text-sm" muted={true}>Все события в радиусе 200км</TypographyBodySmall>
            </div>
            <div className="w-full mt-auto z-20">
                <Button onClick={() => redirectToEventsPage()}>Найти событие</Button>
            </div>
            <CatLight className="absolute right-[-3px] bottom-[-2px] z-10" width="261.664" height="257.74"/>
        </div>
    )
}