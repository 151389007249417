import {createGate} from "effector-react";
import {createEvent, sample} from "effector";
import {redirect} from "atomic-router";
import {eventCreatePageRoute} from "shared/routes";

export const MainPageGate = createGate()

export const redirectToEventCreatePage = createEvent()

sample({
    //@ts-expect-error
    clock: redirectToEventCreatePage,
    target: redirect({route: eventCreatePageRoute})
})