import {useGate} from "effector-react";
import {useForm} from "effector-forms";
import {UserAvatar} from "features/user-avatar";
import {
    Button,
    DatePicker,
    EventContentContainer,
    InputWithLabel, InputWithMeasureUnit,
    PageHeader,
    PageWrapper,
    TypographyH5
} from "shared/components";
import {ButtonSelect} from "shared/components/ui/button-select";
import {profileEditPageGate, $profileEditForm} from "./model";

export const ProfileEditPage = () => {

    useGate(profileEditPageGate)

    const {fields} = useForm($profileEditForm)

    const options = [
        {value: "male", name: "М"},
        {value: "female", name: "Ж"}
    ]

    return (
        <PageWrapper>
            <div className="flex flex-col gap-2">
                <PageHeader>
                    <div className="flex flex-col gap-4">
                        <TypographyH5 className="text-black text-xl">Твои данные</TypographyH5>
                        <UserAvatar avatarSize="size-16"/>
                        <InputWithLabel onChange={fields.userName.onChange} label="Логин" value={fields.userName.value}/>
                        <InputWithLabel onChange={fields.firstName.onChange} label="Имя" value={fields.firstName.value}/>
                        <DatePicker label="Дата рождения" date={fields.birthDate.value} onSelect={fields.birthDate.onChange} className="w-full"/>
                        <div className="flex gap-6">
                            <ButtonSelect onSelect={fields.gender.onChange} selected={fields.gender.value} items={options} label="Пол"/>
                            <InputWithMeasureUnit onChange={fields.weight.onChange} measureUnit="кг" label="Вес"/>
                            <InputWithMeasureUnit onChange={fields.height.onChange} measureUnit="см" label="Рост"/>
                        </div>
                    </div>
                </PageHeader>
                <EventContentContainer title="Виды спорта" subTitle="Выберите вид спорта и станут доступны интересующие тебя события">
                    <Button variant="secondary" className="rounded-full">Выбрать</Button>
                </EventContentContainer>
                <EventContentContainer title="Территориально" subTitle="Укажи точку на карте. Это поможет находить ближайшие площадки или игроков рядом с тобой">
                    <Button variant="secondary" className="rounded-full">Указать на карте</Button>
                </EventContentContainer>
                <EventContentContainer title="Связь" subTitle="Добавь контактные данные, по которым с тобой можно связаться">
                    <div className="flex gap-6">
                        <Button variant="secondary" className="rounded-full w-1/2">Вконтакте</Button>
                        <Button variant="secondary" className="rounded-full w-1/2">Номер телефона</Button>
                    </div>
                    <Button>Сохранить</Button>
                </EventContentContainer>
            </div>
        </PageWrapper>
    )
}