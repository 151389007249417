import {useGate} from "effector-react";
import {NavigationScrollBar} from "widgets/navigation-scroll-bar";
import {CreateEventCard} from "features/create-event-card";
import {MyTeamCard} from "features/my-team-card";
import {UserAvatar} from "features/user-avatar";
import {$sessionUser} from "entities/session";
import {EventContentContainer, PageWrapper} from "shared/components";
import {MainPageGate, redirectToEventCreatePage} from "./model";
import {useUnit} from "effector-react/compat";

export const MainPage = () => {

    useGate(MainPageGate)

    const sessionUser = useUnit($sessionUser)

    return (
        <PageWrapper>
            <div className="flex flex-col gap-4">
                <UserAvatar userName={sessionUser?.userName} photoUrl={sessionUser?.photoUrl} firstName={sessionUser?.firstName}/>
                <NavigationScrollBar/>
                <div className="flex flex-col gap-2">
                    <EventContentContainer>
                        <CreateEventCard onClick={redirectToEventCreatePage}/>
                    </EventContentContainer>
                    <EventContentContainer className="min-h-[118px]">
                        <MyTeamCard/>
                    </EventContentContainer>
                </div>
            </div>
        </PageWrapper>
    )
}

