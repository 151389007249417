import {PhotoCloud, TypographyBodySmall, TypographyH4} from "shared/components";

export const MyTeamCard = () => {

    return (
        <div className="flex flex-col gap-2 z-10">
            <div className="flex flex-col gap-1 z-30">
                <TypographyH4 className="text-black text-xl">Моя команда</TypographyH4>
                <TypographyBodySmall className="text-slate-400 text-sm">Приглашайте своих</TypographyBodySmall>
            </div>
            <PhotoCloud className="right-[261px] bottom-[109px] z-80"/>
        </div>
    )
}