import {FC} from "react";
import {Button, TypographyBody, TypographyBodySmall} from "shared/components";
import {EditIcon} from "shared/components/img-components/edit-icon";

interface ISportTypeCard {
    sportName: string;
    position: string;
    skill: string;
}

export const SportTypeCard: FC<ISportTypeCard> = ({
                                                      sportName,
                                                      position= "",
                                                      skill = ""
                                                  }) => {
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
                <TypographyBody className="m-0">{sportName}</TypographyBody>
            </div>
            <div className="flex items-center gap-6">
                <TypographyBodySmall>{`${position} · ${skill}`}</TypographyBodySmall>
                <Button variant="outline" size="icon" className="size-8"><EditIcon /></Button>
            </div>
        </div>
    )
}