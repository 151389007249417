import {FC, ReactNode} from "react";
import {TypographyCaption, TypographyH5} from "shared/components";

interface IEventContentContainer {
    children: ReactNode;
    onClick?: () => {};
    className?: string;
    title?: string;
    subTitle?: string;
}

export const EventContentContainer: FC<IEventContentContainer> = ({
                                                                      children,
                                                                      onClick,
                                                                      className,
                                                                      title,
                                                                      subTitle
                                                                  }) => {
    return (
        <div className={`flex flex-col p-4 gap-4 rounded-2xl w-full bg-white relative overflow-hidden z-10 ${className}`} onClick={onClick}>
            {(title || subTitle) &&
                <div>
                    {title && <TypographyH5 className="text-black text-xl">
                        {title}
                    </TypographyH5>
                    }
                    {subTitle && <TypographyCaption className="text-black text-xs" muted={true}>
                        {subTitle}
                    </TypographyCaption>
                    }
                </div>
            }
            {children}
        </div>
    )
}