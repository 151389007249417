import { Clock } from "lucide-react";
import {FC} from "react";
import {Input, Label} from "shared/components";

interface ITimePicker {
    label?: string;
    className?:string;
    time: string;
    onChange: (arg1: string) => void;
    placeholder?: string;
}

export const TimePicker: FC<ITimePicker> = ({
                                                label,
                                                className,
                                                time,
                                                onChange,
                                                placeholder = "Введите время"
}) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let formattedValue = value.replace(/[^\d:]/g, "");
        if (formattedValue.length > 5) {
            formattedValue = formattedValue.slice(0, 5);
        }
        if (formattedValue.length > 2 && formattedValue[2] !== ":") {
            formattedValue = formattedValue.slice(0, 2) + ":" + formattedValue.slice(2);
        }
        onChange(formattedValue);
    };

    return (
        <div className={`${className} gap-2`}>
            <Label htmlFor="timeInput" className="text-sm font-medium text-foreground">
                {label}
            </Label>
            <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-3 z-10 flex items-center justify-center text-muted-foreground/80">
                    <Clock size={16} strokeWidth={2} aria-hidden="true" />
                </div>
                <Input
                    id="timeInput"
                    value={time}
                    onChange={handleChange}
                    className={`relative inline-flex h-10 w-full items-center overflow-hidden whitespace-nowrap rounded-lg border border-input bg-background px-3 py-2 pl-9 text-sm shadow-sm shadow-black/5 transition-shadow focus-within:border-ring focus-within:outline-none focus-within:ring-[3px] focus-within:ring-ring/20`}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
}