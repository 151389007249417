import {FC} from "react";

export const DottedLine: FC<{ height?: number; className?: string }> = ({
                                                                            height = 1,
                                                                            className
                                                                        }) => {
    return (
        <div
            className={`flex-grow bg-repeat-x bg-center bg-[length:4px_100%] ${className}`}
            style={{
                height: `${height}px`,
                backgroundImage: `repeating-linear-gradient(90deg, var(--black, #000) 0, var(--black, #000) 2px, transparent 2px, transparent 4px)`,
                opacity: 'var(--50, 0.5)',
            }}
        ></div>
    );
};