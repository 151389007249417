import {FC} from "react";
import {ISvgComponent} from "shared/components/img-components/types";

export const DeleteIcon: FC<ISvgComponent> = ({
                                                      width = "16",
                                                      height = "16",
                                                      className
                                                  }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.83333 2.66683C5.83333 2.49611 5.92373 2.28354 6.10355 2.10372C6.28338 1.92389 6.49595 1.8335 6.66667 1.8335H9.33333C9.50405 1.8335 9.71662 1.92389 9.89645 2.10372C10.0763 2.28354 10.1667 2.49611 10.1667 2.66683V3.50016H5.83333V2.66683ZM4.83333 3.50016V2.66683C4.83333 2.17088 5.07627 1.71678 5.39645 1.39661C5.71662 1.07644 6.17072 0.833496 6.66667 0.833496H9.33333C9.82928 0.833496 10.2834 1.07644 10.6036 1.39661C10.9237 1.71678 11.1667 2.17088 11.1667 2.66683V3.50016H12.6667H14C14.2761 3.50016 14.5 3.72402 14.5 4.00016C14.5 4.27631 14.2761 4.50016 14 4.50016H13.1667V13.3335C13.1667 13.8294 12.9237 14.2835 12.6036 14.6037C12.2834 14.9239 11.8293 15.1668 11.3333 15.1668H4.66667C4.17072 15.1668 3.71662 14.9239 3.39645 14.6037C3.07627 14.2835 2.83333 13.8294 2.83333 13.3335V4.50016H2C1.72386 4.50016 1.5 4.27631 1.5 4.00016C1.5 3.72402 1.72386 3.50016 2 3.50016H3.33333H4.83333ZM10.6667 4.50016H5.33333H3.83333V13.3335C3.83333 13.5042 3.92373 13.7168 4.10355 13.8966C4.28338 14.0764 4.49595 14.1668 4.66667 14.1668H11.3333C11.5041 14.1668 11.7166 14.0764 11.8964 13.8966C12.0763 13.7168 12.1667 13.5042 12.1667 13.3335V4.50016H10.6667ZM6.66667 6.8335C6.94281 6.8335 7.16667 7.05735 7.16667 7.3335V11.3335C7.16667 11.6096 6.94281 11.8335 6.66667 11.8335C6.39052 11.8335 6.16667 11.6096 6.16667 11.3335V7.3335C6.16667 7.05735 6.39052 6.8335 6.66667 6.8335ZM9.83333 7.3335C9.83333 7.05735 9.60948 6.8335 9.33333 6.8335C9.05719 6.8335 8.83333 7.05735 8.83333 7.3335V11.3335C8.83333 11.6096 9.05719 11.8335 9.33333 11.8335C9.60948 11.8335 9.83333 11.6096 9.83333 11.3335V7.3335Z"
                  fill="white"/>
        </svg>
    )
}