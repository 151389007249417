import {createForm} from "effector-forms";
import {createStore, sample} from "effector";
import {$sportTypes} from "entities/sport-types";
import {ISportTypes} from "entities/sport-types/types";


interface ISportFilterForm {
    selectedSportTypes: string[] |  [];
}

export const $sportIdFieldItems = createStore<ISportTypes[] | null>(null)

export const $sportFilterForm = createForm<ISportFilterForm>({
    fields: {
        selectedSportTypes: {
            init: ["BASKETBALL"]
        }
    },
})

sample({
    clock: $sportTypes,
    target: $sportIdFieldItems
})