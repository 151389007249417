import {createForm} from "effector-forms";
import {attach, createEvent, createStore, sample} from "effector";
import {redirect} from "atomic-router";
import {$sportTypes} from "entities/sport-types";
import {ISportTypes} from "entities/sport-types/types";
import {createEventFx} from "shared/api";
import {mainPageRoute} from "shared/routes";
import {rules} from "./rules";
import {IEventCreateForm} from "./types";
import {buildCreateEventRequestData} from "./lib";

const attachedCreateEventFx = attach({effect: createEventFx})
export const formValidate = createEvent("Валидация формы")
export const $sportIdFieldItems = createStore<ISportTypes[] | null>(null)
export const $eventCreateForm = createForm<IEventCreateForm>({
    fields: {
        sportCode: {
            init: null,
            rules: [rules.required()]
        },
        date: {
            init: null,
            rules: [rules.required()]
        },
        time: {
            init: "",
            rules: [rules.required()]
        },
        duration: {
            init: null,
            rules: [rules.required()]
        },
        locationName: {
            init: null,
            rules: [rules.required()]
        },
        locationAddress: {
            init: null,
            rules: [rules.required()]
        },
        locationDescription: {
            init: null,
        },
        locationCoordinates: {
            init: "54.719602, 55.939368"
        },
        locationPhotos: {
            init: null,
        },
        seats: {
            init: null,
            rules: [rules.required()]
        },
        ownerIsPlaying: {
            init: false,
            rules: [rules.required()]
        },
        isFree: {
            init: true,
            rules: [rules.required()]
        },
        cost: {
            init: 0,
            rules: [rules.required()]
        },
        locationProperties: {
            init: {}
        },
        isAgePeriod: {
            init: false,
            rules: [rules.required()]
        },
        ageFrom: {
            init: null,
        },
        ageTo: {
            init: null,
        },
    },
})

sample({
    clock: $sportTypes,
    target: $sportIdFieldItems
})

sample({
    //@ts-expect-error
    clock: formValidate,
    target: $eventCreateForm.validate,
});

sample({
    //@ts-expect-error
    clock: $eventCreateForm.formValidated,
    fn: buildCreateEventRequestData,
    target: attachedCreateEventFx
})

sample({
    //@ts-expect-error
    clock: attachedCreateEventFx.doneData,
    target: redirect({route: mainPageRoute})
})