import {sportTypes} from "shared/constants/sport-types";

export const icons: TIcons = {
    [sportTypes.BASKETBALL]: '\u{1F3C0}',
    [sportTypes.FOOTBALL]: '\u{26BD}',
    [sportTypes.VOLLEYBALL]: '\u{1F3D0}',
    [sportTypes.TENNIS]: '\u{1F3BE}',
    [sportTypes.TABLE_TENNIS]: '\u{1F3D3}'
}

type TIcons = {
    [key in keyof typeof sportTypes]: string;
};