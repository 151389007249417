import {useGate, useUnit} from "effector-react";
import {useForm} from "effector-forms";
import {EventCard} from "features/event-card";
import {
    CatLight,
    MultiSelectDrawer,
    PageHeader,
    PageWrapper,
    TypographyBody,
    TypographyBodySmall,
    TypographyH2
} from "shared/components";
import {$eventsGroupedByDate, $sportFilterForm, $sportIdFilterItems, eventsPageGate, goToEvent} from "./model";
import {dateTimeTransform} from "shared/lib";

export const EventsPage = () => {
    useGate(eventsPageGate)

    const sportFilterForm = useForm($sportFilterForm)
    const [events, sportIdFilterItems]= useUnit([$eventsGroupedByDate, $sportIdFilterItems])

    return (
        <PageWrapper>
            <div className="flex flex-col gap-2">
                <PageHeader isFullWidth={true} backgroundColor="orange-400">
                    <div className="inline-flex flex-col gap-6">
                        <TypographyH2 className="text-white text-3xl w-1/2 p-2 z-30">Ближайшие события</TypographyH2>
                        <MultiSelectDrawer
                            MultiSelectOptions={sportIdFilterItems?.map(({icon, code, name}) => ({
                                icon,
                                name,
                                value: code
                            }))}
                            onSelect={(value) => sportFilterForm.fields.selectedSportTypes.onChange(value)}
                            values={sportFilterForm.fields.selectedSportTypes.value}
                        />
                        <CatLight width="285.97px" height="281.68px" className="absolute right-[-40px] bottom-[-109px]"/>
                    </div>
                </PageHeader>
                <div className="flex flex-col gap-6">
                    {
                        events
                            ? Object.keys(events).map((eventDate) => {
                                const isoDate = (new Date(eventDate)).toISOString()
                                const {date, weekday} = dateTimeTransform(isoDate, "D Mmm")

                                return (
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between px-2">
                                            <TypographyBody className="text-white text-lg">{date}</TypographyBody>
                                            <TypographyBodySmall className="text-white text-base"
                                                                 muted={true}>{weekday}</TypographyBodySmall>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            {
                                                events[eventDate].map(event => {
                                                    //@ts-expect-error
                                                    return <EventCard sportCode={event.sport.code} address={event.address}
                                                                      dateTime={event.dateTime} cost={event.cost} seats={event.seats}
                                                                      onClick={goToEvent} eventId={event.id} key={event.id}
                                                        // @ts-ignore
                                                                      participants={event.participants} />
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            : <TypographyBody>Пока нет событий</TypographyBody>
                    }
                </div>
            </div>
        </PageWrapper>
    )
}