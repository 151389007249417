import React, {FC} from "react";
import {Input, Label, Skeleton} from "shared/components";

interface IInputWithLabel {
    label?: string;
    placeholder?: string;
    className?: string;
    type?: string;
    onChange: (arg: string) => void;
    pending?: boolean;
    value?: string;
}

export const InputWithLabel: FC<IInputWithLabel> = ({
                                                        label,
                                                        placeholder,
                                                        className,
                                                        type,
                                                        onChange = () => {},
                                                        pending = false,
                                                        value
                                                    }) => {
    if (pending) {
        return <Skeleton className="h-10 w-full"/>
    }

    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="relative">
                <Input placeholder={placeholder} type={type} onChange={event => onChange(event.target.value)} value={value}/>
            </div>
        </div>
    );
}
