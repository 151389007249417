import React, {FC} from "react";
import {Label, Textarea} from "shared/components";

interface ITextareaWithLabel {
    label?: string;
    placeholder?: string;
    className?: string;
    resize?: "resize-none" | "resize";
    onChange: (arg1: string) => void;
}

export const TextareaWithLabel: FC<ITextareaWithLabel> = ({
                                                              label,
                                                              placeholder,
                                                              className,
                                                              resize = "resize-none",
                                                              onChange = () => {}
                                                          }) => {
    return (
        <div className={`gap-2 ${className}`}>
            {label && <Label>{label}</Label>}
            <div className="relative">
                <Textarea placeholder={placeholder} className={resize}
                          onChange={(event) => onChange(event.target.value)}/>
            </div>
        </div>
    );
}
