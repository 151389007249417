import {FC} from "react";
import {Button, TypographyBodySmall, TypographyH4} from "shared/components";
import BasketballBall from "shared/assets/basketball-ball.png"

export const CreateEventCard: FC<{onClick: () => void}> = ({onClick}) => {
    return (
        <div className="flex flex-col gap-2 z-20">
            <div className="flex flex-col gap-1 z-20">
                <TypographyH4 className="text-black text-xl">Создай событие</TypographyH4>
                <TypographyBodySmall className="text-slate-400 text-sm">Собери людей, чтобы поиграть</TypographyBodySmall>
            </div>
            <div className="z-20">
                <Button onClick={() => onClick()}>Создать событие</Button>
            </div>
            <img className="absolute rotate-[27.502deg] size-[161.008px] right-[-30px] bottom-[-70px] z-10" src={BasketballBall} alt="BasketballBall"/>
        </div>
    )
}